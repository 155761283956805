<template>
  <div>
    <a-skeleton :loading="isProfileLoading" active>
      <div class="row" v-if="!isProfileLoading">
        <div class="col-md-9">
          <div class="card card-top card-top-success">
            <div>
              <div class="card-header py-0">
                <ProfileHeader
                  :display-name="profile?.customersDoc?.displayName"
                  :doc-id="docId"
                  @reloadProfile="handleProfileReload"
                />
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4" style="border-right: 2px solid #f0f0f0; padding: 0px">
                  <ProfileAvatar :customers-doc="profile?.customersDoc" />
                </div>
                <div class="col-md-8">
                  <BasicInfo
                    :customers-doc="profile?.customersDoc"
                    :opening-balance="profile?.customerBooksDoc?.openingBalance"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card card-top">
            <div>
              <div class="card-header py-0">
                <div>
                  <a-tabs v-model:active-key="activeTabKey" tab-position="top">
                    <a-tab-pane key="1">
                      <template #tab>
                        <a class="nav-link" href="javascript: void(0);">Overview</a>
                      </template>
                    </a-tab-pane>
                    <a-tab-pane key="2">
                      <template #tab>
                        <a class="nav-link" href="javascript: void(0);">Consignments</a>
                      </template>
                    </a-tab-pane>
                    <a-tab-pane key="3">
                      <template #tab>
                        <a class="nav-link" href="javascript: void(0);">Invoices</a>
                      </template>
                    </a-tab-pane>
                    <a-tab-pane key="4">
                      <template #tab>
                        <a class="nav-link" href="javascript: void(0);">Rates</a>
                      </template>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTabKey === '1'">
            <div id="contact-persons-card">
              <div class="card card-top">
                <div class="card-header py-0">
                  <div class="card-header-flex align-items-center">
                    <div class="d-flex flex-column justify-content-center mr-auto">
                      <h5 class="mb-0">
                        <div class="text-dark font-size-18">
                          Contact Persons
                        </div>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <ContactPersonsTable :contact-persons="profile?.customersDoc?.contactPersons" />
                  </div>
                </div>
              </div>
            </div>
            <!-- Add other cards for tab key 1 here -->
          </div>
          <!-- Add other tab keys with v-if here -->
          <div v-else>
            <div class="card card-top">
              Coming Soon..
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-top card-top-success">
            <div class="card-body">
              <BasicTextValue
                title="Outstanding Receivables"
                description="Amount to be received from customer"
                :value="profile?.customerBooksDoc?.outstanding ?? 'N/A'"
              />
              <a-divider />
              <BasicTextValue
                title="Unused Credits"
                description="Amount received from customer but not used"
                :value="profile?.customerBooksDoc?.unusedCredits ?? 'N/A'"
              />
            </div>
          </div>
          <div class="card card-top">
            <div class="card-body">
              <HorizontalTableLoop
                title="Audit"
                description="Creation/Modification Details"
                :table-data="auditData"
              />
            </div>
          </div>
          <!-- This is for Books ? -->
        </div>
      </div>
    </a-skeleton>
  </div>
</template>

<script lang="ts">
//  onBeforeMount, ref
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import ProfileAvatar from './ProfileAvatar.vue'
import BasicInfo from './BasicInfo.vue'
import BasicTextValue from '../../../../components/General/BasicTextValue.vue'
import HorizontalTableLoop from '@/components/General/HorizontalTableLoop.vue'
import ProfileHeader from './ProfileHeader.vue'
import ContactPersonsTable from './ContactPersonsTable.vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { CustomerBooksDocument } from '@/types/firebaseCollectionContracts/CustomerBooksDocument'
import { GetDateFromNow, GetShortDateFormat } from '@/services/helpers'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    ProfileHeader,
    ProfileAvatar,
    BasicInfo,
    BasicTextValue,
    HorizontalTableLoop,
    ContactPersonsTable,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isProfileLoading = ref(false)
    const docId = computed(() => route.params.id)
    const profile = ref<{
      customersDoc: CustomersDocument
      customerBooksDoc: CustomerBooksDocument
    } | null>(null)
    const activeTabKey = ref('1')
    const auditData = computed(() => {
      const aud = profile?.value?.customersDoc.audit
      return {
        'Created By': aud?.createdByEmail,
        'Created On': `${GetDateFromNow(aud?.createdOn.toDate())} (${GetShortDateFormat(
          aud?.createdOn.toDate(),
        )})`,
        'Modified By': aud?.modifiedByEmail,
        'Modified On': `${GetDateFromNow(aud?.modifiedOn.toDate())} (${GetShortDateFormat(
          aud?.modifiedOn.toDate(),
        )})`,
        'Import ID': aud?.importId,
      }
    })

    const fetchProfile = async (cid?: string) => {
      isProfileLoading.value = true
      const resp = await store.dispatch(
        `customers/${actions.CustomersAction.FETCH_CUSTOMER_PROFILE}`,
        { id: cid },
      )
      if (!resp.customerBooksDoc || !resp.customersDoc) {
        message.warn('Customer does not exists.')
        router.push({ name: 'customers' })
      } else {
        profile.value = resp
      }
      isProfileLoading.value = false
    }

    const handleProfileReload = async () => {
      await fetchProfile(route.params.id as string)
    }

    onBeforeMount(async () => {
      await fetchProfile(route.params.id as string)
    })

    onBeforeRouteUpdate(async (to) => {
      await fetchProfile(to.params.id as string)
    })

    return {
      auditData,
      isProfileLoading,
      profile,
      activeTabKey,
      docId,
      handleProfileReload,
    }
  },
})
</script>

<style scoped></style>
