<template>
  <div>
    <div class="d-flex flex-wrap flex-column align-items-center">
      <div>
        <a-popover placement="right">
          <template #content>
            <a-button type="link" @click="handlePhotoUpdate">Update Photo</a-button>
          </template>
          <div class="cust-profile-avatar">
            <a-avatar
              :size="86"
              :src="photoUrl"
              style="color: #595c97; background-color: #f2f4f8;"
              class="mb-2 mt-2 flex-shrink-0"
            >
              <UserOutlined />
            </a-avatar>
          </div>
        </a-popover>
      </div>
      <div class="text-center">
        <div class="text-dark font-weight-bold font-size-18">
          {{ customer?.cid || 'N/A' }}
        </div>
        <div class="font-size-16 mb-1">
          <a :href="`mailto:${customer?.email}}}`">{{ customer?.email || 'N/A' }}</a>
        </div>
        <div class="mb-3">
          <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">
            {{ customer?.customerType || 'N/A' }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-wrap mb-3">
        <a :href="customer?.social.facebook || '#'" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-2x fa-facebook-square p-2"
        /></a>
        <a :href="customer?.social.linkedin || '#'" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-2x fa-linkedin p-2"
        /></a>
        <a :href="customer?.social.twitter || '#'" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-2x fa-twitter-square p-2"
        /></a>
      </div>
    </div>
    <div>
      <profile-avatar-update
        v-model:open-avatar-modal="openAvatarModal"
        v-model:photo-url="photoUrl"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { UserOutlined } from '@ant-design/icons-vue'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import ProfileAvatarUpdate from './ProfileAvatarUpdate.vue'

export default defineComponent({
  name: 'ProfileAvatar',
  components: {
    UserOutlined,
    ProfileAvatarUpdate,
  },
  props: {
    customersDoc: {
      type: Object as PropType<CustomersDocument>,
      required: true,
    },
  },
  setup(props) {
    const customer = ref(props.customersDoc)
    const photoUrl = ref(customer.value.photoUrl)
    const openAvatarModal = ref(false)
    const handlePhotoUpdate = () => {
      openAvatarModal.value = true
    }

    return {
      customer,
      handlePhotoUpdate,
      openAvatarModal,
      photoUrl,
    }
  },
})
</script>

<style lang="scss" scoped>
th,
td {
  padding: 0.35rem;
}

.cust-profile-avatar {
  cursor: pointer;
}
</style>
