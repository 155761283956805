
import { defineComponent, PropType, ref } from 'vue'
import { UserOutlined } from '@ant-design/icons-vue'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import ProfileAvatarUpdate from './ProfileAvatarUpdate.vue'

export default defineComponent({
  name: 'ProfileAvatar',
  components: {
    UserOutlined,
    ProfileAvatarUpdate,
  },
  props: {
    customersDoc: {
      type: Object as PropType<CustomersDocument>,
      required: true,
    },
  },
  setup(props) {
    const customer = ref(props.customersDoc)
    const photoUrl = ref(customer.value.photoUrl)
    const openAvatarModal = ref(false)
    const handlePhotoUpdate = () => {
      openAvatarModal.value = true
    }

    return {
      customer,
      handlePhotoUpdate,
      openAvatarModal,
      photoUrl,
    }
  },
})
