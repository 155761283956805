<template>
  <div>
    <ModalSlot
      :modal-visible="isModalVisible"
      modal-title="Update Customer Photo"
      @update:modalVisible="handleModalUpdate"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-lg-7">
            <avatar-browse
              :avatar-size="100"
              :photo-url="photoSrc ?? null"
              :reset="isReset"
              :is-uploading="isUploading"
              :is-deleting="isDeleting"
              :show-delete="true"
              @photoSelected="handlePhotoSelected"
              @deleteExisting="handleAvatarDelete"
            />
          </div>
          <div class="col-lg-5">
            <div class="mb-5">
              <div>
                <p class="text-uppercase text-muted mb-3">Select Profile Picture</p>
                <p class="mb-4">
                  Select a picture from your computer to update customer's display picture.
                </p>
              </div>
              <div>
                <a-button
                  type="primary"
                  html-type="submit"
                  class="text-center mr-2"
                  :disabled="isReset || isDeleting"
                  :loading="isUploading"
                  @click="handleAvatarUpdate(null, null)"
                >
                  <span v-show="!isUploading">Update</span>
                </a-button>
                <a-button
                  class="text-center"
                  @click="handleAvatarReset"
                  :disabled="isReset || isDeleting || isUploading"
                >
                  Reset
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalSlot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, watch } from 'vue'
import ModalSlot from '@/components/General/ModalSlot.vue'
import AvatarBrowse from '@/components/General/AvatarBrowse.vue'
import { useAvatarBrowse } from '@/hooks/useAvatarBrowseHook'
import * as actions from '@/store/actions.type'

export default defineComponent({
  name: 'ProfileAvatarUpdate',
  components: {
    ModalSlot,
    AvatarBrowse,
  },
  props: {
    openAvatarModal: {
      type: Boolean,
      default: () => false,
    },
    photoUrl: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:openAvatarModal', 'update:photoUrl'],
  setup(props, { emit }) {
    const { photoUrl } = toRefs(props)
    const {
      isReset,
      isUploading,
      isDeleting,
      returnPhotoUrl,
      handlePhotoSelected,
      handleAvatarUpdate,
      handleAvatarDelete,
      handleAvatarReset,
    } = useAvatarBrowse(
      photoUrl,
      `customers/${actions.CustomersAction.UPDATE_CUSTOMER_PHOTO}`,
      `customers/${actions.CustomersAction.UPDATE_CUSTOMER_PHOTO}`,
    )
    const isModalVisible = computed(() => props.openAvatarModal)

    const handleModalUpdate = () => {
      emit('update:openAvatarModal', false)
    }

    watch(returnPhotoUrl, () => {
      emit('update:photoUrl', returnPhotoUrl.value)
    })

    return {
      isModalVisible,
      handleModalUpdate,
      handlePhotoSelected,
      handleAvatarUpdate,
      handleAvatarReset,
      photoSrc: photoUrl,
      isReset,
      isUploading,
      isDeleting,
      handleAvatarDelete,
    }
  },
})
</script>

<style scoped></style>
