<template>
  <div class="table-responsive text-nowrap">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :bordered="true"
      :row-key="(_, idx) => idx"
      :pagination="pagination"
      size="small"
    >
      <template #expandedRowRender="{ record }">
        <p style="margin: 0">
          {{ record.cpNotes || 'No Notes.' }}
        </p>
      </template>
      <template #action>
        <span>
          <a-tooltip placement="top" title="Edit Contact">
            <a href="javascript: void(0);" class="mr-2">
              <i class="fe fe-edit" />
            </a>
          </a-tooltip>
          <a-tooltip placement="top" title="Delete Contact">
            <a href="javascript: void(0);">
              <i class="fe fe-trash" />
            </a>
          </a-tooltip>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
const columns = [
  {
    title: 'Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    key: 'cpEmail',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
const pagination = {
  position: 'bottom',
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
}

import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ContactPersonsTable',
  props: {
    contactPersons: {
      type: Object as PropType<IContactPerson[]>,
      required: true,
    },
  },
  setup(props) {
    const dataSource = computed(() => props.contactPersons)
    return {
      columns,
      pagination,
      dataSource,
    }
  },
})
</script>

<style scoped></style>
