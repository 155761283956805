<template>
  <div>
    <div class="table-responsive">
      <table
        class="table table-borderless"
        v-for="(dataObject, idx) in customerDataArray"
        :key="idx"
      >
        <thead>
          <tr>
            <th scope="col" v-for="(_, name, ix) in dataObject" :key="ix">
              {{ name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(value, _, ix) in dataObject" :key="ix">
              <span v-if="_ === 'Opening Balance'">&#8377;</span> {{ value || 'N/A' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProfileBasicInfo',
  props: {
    customersDoc: {
      type: Object as PropType<CustomersDocument | null>,
      required: true,
    },
    openingBalance: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const customer = computed(() => props.customersDoc)
    const customerDataArray = computed(() => {
      return [
        {
          'Full Name': customer?.value?.fullName,
          Company: customer?.value?.companyName,
          'Phone Number': customer?.value?.mobile,
        },
        {
          PAN: customer?.value?.pan,
          GSTIN: customer?.value?.gstin,
          'Opening Balance': props.openingBalance,
        },
        {
          City: customer?.value?.primaryAddress.city,
          State: customer?.value?.primaryAddress.state,
          Pincode: customer?.value?.primaryAddress.pincode,
        },
        {
          'Street Address': customer?.value?.primaryAddress.address,
        },
      ]
    })
    return {
      customerDataArray,
    }
  },
})
</script>

<style lang="scss" scoped>
th,
td {
  width: 33%;
  padding: 0.25rem;
}
</style>
