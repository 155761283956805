
//  onBeforeMount, ref
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import ProfileAvatar from './ProfileAvatar.vue'
import BasicInfo from './BasicInfo.vue'
import BasicTextValue from '../../../../components/General/BasicTextValue.vue'
import HorizontalTableLoop from '@/components/General/HorizontalTableLoop.vue'
import ProfileHeader from './ProfileHeader.vue'
import ContactPersonsTable from './ContactPersonsTable.vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { CustomerBooksDocument } from '@/types/firebaseCollectionContracts/CustomerBooksDocument'
import { GetDateFromNow, GetShortDateFormat } from '@/services/helpers'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    ProfileHeader,
    ProfileAvatar,
    BasicInfo,
    BasicTextValue,
    HorizontalTableLoop,
    ContactPersonsTable,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isProfileLoading = ref(false)
    const docId = computed(() => route.params.id)
    const profile = ref<{
      customersDoc: CustomersDocument
      customerBooksDoc: CustomerBooksDocument
    } | null>(null)
    const activeTabKey = ref('1')
    const auditData = computed(() => {
      const aud = profile?.value?.customersDoc.audit
      return {
        'Created By': aud?.createdByEmail,
        'Created On': `${GetDateFromNow(aud?.createdOn.toDate())} (${GetShortDateFormat(
          aud?.createdOn.toDate(),
        )})`,
        'Modified By': aud?.modifiedByEmail,
        'Modified On': `${GetDateFromNow(aud?.modifiedOn.toDate())} (${GetShortDateFormat(
          aud?.modifiedOn.toDate(),
        )})`,
        'Import ID': aud?.importId,
      }
    })

    const fetchProfile = async (cid?: string) => {
      isProfileLoading.value = true
      const resp = await store.dispatch(
        `customers/${actions.CustomersAction.FETCH_CUSTOMER_PROFILE}`,
        { id: cid },
      )
      if (!resp.customerBooksDoc || !resp.customersDoc) {
        message.warn('Customer does not exists.')
        router.push({ name: 'customers' })
      } else {
        profile.value = resp
      }
      isProfileLoading.value = false
    }

    const handleProfileReload = async () => {
      await fetchProfile(route.params.id as string)
    }

    onBeforeMount(async () => {
      await fetchProfile(route.params.id as string)
    })

    onBeforeRouteUpdate(async (to) => {
      await fetchProfile(to.params.id as string)
    })

    return {
      auditData,
      isProfileLoading,
      profile,
      activeTabKey,
      docId,
      handleProfileReload,
    }
  },
})
