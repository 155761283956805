<template>
  <div>
    <div class="d-flex flex-wrap align-items-center">
      <div class="mr-auto">
        <p class="text-uppercase text-dark font-weight-bold mb-1">
          {{ props.title || 'N/A' }}
        </p>
        <p class="text-gray-5 mb-0">
          {{ props.description || 'N/A' }}
        </p>
      </div>
      <p class="font-weight-bold font-size-24 mb-0">
        <span v-if="props.isAmount">&#8377;</span> {{ props.value ?? 'N/A' }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    isAmount: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    return {
      props,
    }
  },
})
</script>

<style scoped></style>
