
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProfileBasicInfo',
  props: {
    customersDoc: {
      type: Object as PropType<CustomersDocument | null>,
      required: true,
    },
    openingBalance: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const customer = computed(() => props.customersDoc)
    const customerDataArray = computed(() => {
      return [
        {
          'Full Name': customer?.value?.fullName,
          Company: customer?.value?.companyName,
          'Phone Number': customer?.value?.mobile,
        },
        {
          PAN: customer?.value?.pan,
          GSTIN: customer?.value?.gstin,
          'Opening Balance': props.openingBalance,
        },
        {
          City: customer?.value?.primaryAddress.city,
          State: customer?.value?.primaryAddress.state,
          Pincode: customer?.value?.primaryAddress.pincode,
        },
        {
          'Street Address': customer?.value?.primaryAddress.address,
        },
      ]
    })
    return {
      customerDataArray,
    }
  },
})
