<template>
  <div class="card-header-flex align-items-center">
    <div class="d-flex flex-column justify-content-center mr-auto">
      <h5 class="mb-0">
        <div class="text-dark font-weight-bold font-size-24">
          {{ name || 'N/A' }}
        </div>
      </h5>
    </div>
    <div>
      <button-group-actions
        :button-list="actionButtonList"
        @handleEditClick="handleEditClick"
        @handleRefreshClick="handleRefresh"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ProfileHeader',
  components: {
    ButtonGroupActions,
  },
  props: {
    displayName: {
      type: String, // Add Undefined
      default: () => '',
      required: true,
    },
    docId: {
      type: String,
      required: true,
    },
  },
  emits: ['reloadProfile'],
  setup(props, { emit }) {
    const router = useRouter()
    const name = computed(() => props.displayName)
    const id = computed(() => props.docId)
    const reloadIconType = ref(false)
    const actionButtonList = [
      ActionButtonList.EDIT,
      ActionButtonList.FAVOURITE,
      ActionButtonList.REFRESH,
      ActionButtonList.DELETE,
    ]
    const handleEditClick = () => {
      router.replace({ name: 'editCustomer', params: { id: id.value } })
    }
    const handleRefresh = () => {
      reloadIconType.value = true
      emit('reloadProfile')
      reloadIconType.value = false
    }
    return {
      name,
      id,
      reloadIconType,
      handleRefresh,
      actionButtonList,
      handleEditClick,
    }
  },
})
</script>
