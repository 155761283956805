
import { computed, defineComponent, toRefs, watch } from 'vue'
import ModalSlot from '@/components/General/ModalSlot.vue'
import AvatarBrowse from '@/components/General/AvatarBrowse.vue'
import { useAvatarBrowse } from '@/hooks/useAvatarBrowseHook'
import * as actions from '@/store/actions.type'

export default defineComponent({
  name: 'ProfileAvatarUpdate',
  components: {
    ModalSlot,
    AvatarBrowse,
  },
  props: {
    openAvatarModal: {
      type: Boolean,
      default: () => false,
    },
    photoUrl: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:openAvatarModal', 'update:photoUrl'],
  setup(props, { emit }) {
    const { photoUrl } = toRefs(props)
    const {
      isReset,
      isUploading,
      isDeleting,
      returnPhotoUrl,
      handlePhotoSelected,
      handleAvatarUpdate,
      handleAvatarDelete,
      handleAvatarReset,
    } = useAvatarBrowse(
      photoUrl,
      `customers/${actions.CustomersAction.UPDATE_CUSTOMER_PHOTO}`,
      `customers/${actions.CustomersAction.UPDATE_CUSTOMER_PHOTO}`,
    )
    const isModalVisible = computed(() => props.openAvatarModal)

    const handleModalUpdate = () => {
      emit('update:openAvatarModal', false)
    }

    watch(returnPhotoUrl, () => {
      emit('update:photoUrl', returnPhotoUrl.value)
    })

    return {
      isModalVisible,
      handleModalUpdate,
      handlePhotoSelected,
      handleAvatarUpdate,
      handleAvatarReset,
      photoSrc: photoUrl,
      isReset,
      isUploading,
      isDeleting,
      handleAvatarDelete,
    }
  },
})
