
const columns = [
  {
    title: 'Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    key: 'cpEmail',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
const pagination = {
  position: 'bottom',
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
}

import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ContactPersonsTable',
  props: {
    contactPersons: {
      type: Object as PropType<IContactPerson[]>,
      required: true,
    },
  },
  setup(props) {
    const dataSource = computed(() => props.contactPersons)
    return {
      columns,
      pagination,
      dataSource,
    }
  },
})
